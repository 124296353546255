import React from 'react'
import styles from './ExclusiveOfferScreen.module.scss'
import { Flex, Spacer, Text } from '@sendoutcards/quantum-design-ui'
import { useMarketingContent } from 'src/react_query'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'
import { CardTypeTile } from 'src/act/components/CardPreview/components/CardTypeTile'
import { CardType } from 'src/graphql/generated/graphql'

export const ExclusiveOfferConfirmationScreen = (props: {
  background?: string
  onConfirm: () => void
  onClose: () => void
}) => {
  const { onConfirm, onClose } = props
  const fontFamily = 'Montserrat, sans-serif'

  const { data: marketingContent } = useMarketingContent()
  const confirmationDialogTitle =
    marketingContent?.exclusiveOfferConfimationTitle.content
  const confirmationDialogContent =
    marketingContent?.exclusiveOfferConfimationContent.content

  const titleStyle: React.CSSProperties = {
    fontFamily: fontFamily,
    fontSize: '28px',
    fontWeight: 700,
    color: '#252525',
  }

  const descriptionStyles: React.CSSProperties = {
    fontFamily: fontFamily,
    fontSize: '14px',
    fontWeight: 600,
    color: '#686868',
    marginTop: '1rem',
  }

  const primaryButtonStyles: React.CSSProperties = {
    borderRadius: '16px',
    display: 'flex',
    justifyContent: 'center',
    boxShadow: '0px 4px 32px 0px rgba(0, 0, 0, 0.08)',
    padding: '8px 20px',
    fontFamily: fontFamily,
    alignItems: 'center',
    fontWeight: 600,
    color: '#fff',
    fontSize: '16px',
    background: 'linear-gradient(to right, #E87592, #98A3FD)',
    cursor: 'pointer',
  }

  return (
    <div className={styles.confirmDialogBackgroundContainer}>
      <div
        style={{
          position: 'absolute',
          top: 20,
          height: '50%',
          width: '100%',
          backgroundImage: `url(https://soc-website-public.s3.amazonaws.com/static/soc-website/images/manual/PremiumCardCoversGrid.png)`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      />
      <div className={styles.container}>
        <Flex
          flexDirection={'row'}
          width={'100%'}
          justifyContent={'end'}
          minHeight={'12px'}
        >
          {onClose !== undefined && (
            <button className={styles.closeButton} onClick={() => onClose()}>
              <Icon name={'cancel'} color="black" size={12} />
            </button>
          )}
        </Flex>

        <div className={styles.offerCard}>
          <div className={styles.successIcon}>
            <img
              src={
                'https://soc-website-public.s3.amazonaws.com/static/soc-website/images/manual/SuccessIcon.png'
              }
              alt="GoodVibesCardFrontPanel"
            />
          </div>
          <Text
            type="body"
            style={{ ...titleStyle }}
            content={confirmationDialogTitle}
            alignment="center"
          />
          <Text
            type="body"
            style={{ ...descriptionStyles }}
            content={confirmationDialogContent}
            alignment="center"
          />
          <Flex flexDirection={'column'}>
            <Flex flexDirection={'row'}></Flex>
          </Flex>
          <div className={styles.featuresSection}>
            <img
              className={styles.trophy}
              src={
                'https://soc-website-public.s3.amazonaws.com/static/soc-website/images/manual/Prize.png'
              }
              alt="Trophy"
            />
            <Text
              type={'body'}
              weight="bold"
              content={'3-Day Trial'}
              alignment="center"
              style={{
                color: '#2A2A2A',
                fontWeight: '700',
                fontSize: '18px',
              }}
            />
            <Text
              type={'footnote'}
              weight="semiBold"
              content={'including:'}
              alignment="center"
            />
            <Flex flexDirection={'row'} columnGap={'x1'}>
              <CardTypeTile
                title={'3 Free Digital Cards'}
                cardType={CardType.Flatcard}
                isSelected={false}
                isHorizontal={false}
                isDigital={true}
                imageUrl={
                  'https://soc-website-public.s3.amazonaws.com/static/soc-website/images/manual/GoodVibes3dCard.png'
                }
                onSelect={() => {}}
              />
              <CardTypeTile
                title={'1 Free Printed Card'}
                cardType={CardType.Flatcard}
                isSelected={false}
                isHorizontal={false}
                imageUrl={
                  'https://soc-website-public.s3.amazonaws.com/static/soc-website/images/manual/GoodVibes3dCard.png'
                }
                onSelect={() => {}}
              />
            </Flex>
          </div>
          <Spacer space="x2_5" />
          <Flex flexDirection={'column'} rowGap={'x1'}>
            <Text
              type={'body'}
              weight="bold"
              content={'Want Unlimited Acts?'}
              style={{ color: '#363239' }}
              alignment="center"
            />
            <div onClick={onConfirm} style={{ ...primaryButtonStyles }}>
              Unlock Unlimited Acts
            </div>
          </Flex>
        </div>
      </div>
    </div>
  )
}
