import { ActionCreator } from '@sendoutcards/core'
// @src imports
import { Result } from 'src/utils/Result'
import { PhoneNumberInput } from 'src/legacy_graphql'
import { AccountFragment, AddressFragment } from 'src/graphql/generated/graphql'

export const updatedAccount = ActionCreator(
  'UPDATED_ACCOUNT',
  (account: AccountFragment | undefined) => ({ account }),
)

export const updatedAccountShippingAddress = ActionCreator(
  'UPDATED_ACCOUNT_SHIPPING_ADDRESS',
  (shippingAddress: AddressFragment) => ({ shippingAddress }),
)

export const saveToken = ActionCreator(
  'SAVE_TOKEN',
  (token: string | undefined) => ({ token }),
)

export const loginDone = ActionCreator(
  'LOGIN_DONE',
  (result: Result<AccountFragment>, rememberMe: boolean = true) => ({
    result,
    rememberMe,
  }),
)

export const loadedUser = ActionCreator(
  'LOADED_USER',
  (account: Result<AccountFragment | null>) => ({ account }),
)

export const unloadUser = ActionCreator('UNLOAD_USER')

export const logout = ActionCreator('LOGOUT')

export const openPhoneNumberVerification = ActionCreator(
  'OPENED_PHONE_VERIFICATION',
  (
    loginPhoneNumber: PhoneNumberInput | null = null,
    needsNewVerificationCode: boolean = false,
    shouldNotCreateNewAccount: boolean = false,
    shouldReassignNumber: boolean = false,
    shouldRememberMe: boolean = true,
  ) => ({
    loginPhoneNumber,
    needsNewVerificationCode,
    shouldNotCreateNewAccount,
    shouldReassignNumber,
    shouldRememberMe,
  }),
)

export const closePhoneNumberVerification = ActionCreator(
  'CLOSED_PHONE_VERIFICATION',
)

export const setUnverifiedAccountsFound = ActionCreator(
  'TOGGLED_UNVERIFIED_ACCOUNTS_FOUND',
  (unverifiedAccountsFound: boolean) => ({ unverifiedAccountsFound }),
)

export const setNoPhoneFound = ActionCreator(
  'TOGGLED_NO_PHONE_FOUND',
  (noPhoneFound: boolean) => ({ noPhoneFound }),
)

export const toggleIsBlankCardModalOpen = ActionCreator(
  'TOGGLED_IS_BLANK_CARD_MODAL_OPEN',
)

export const setProgressiveProfilingDisplayedAt = ActionCreator(
  'SET_PROGRESSIVE_PROFILLING_DISPLAYED_AT',
  (timestamp: string) => ({ timestamp }),
)

export const setUpsaleDisplayedAt = ActionCreator(
  'SET_UPSALE_DISPLAYED_AT',
  (timestamp: string) => ({ timestamp }),
)

export const needsRefreshedAccount = ActionCreator('NEEDS_REFRESHED_ACCOUNT')

export const refreshedAccount = ActionCreator(
  'REFRESHED_ACCOUNT',
  (account: AccountFragment | undefined) => ({ account }),
)

export const setShouldDisplayJoinConfirmation = ActionCreator(
  'SHOULD_DISPLAY_JOINING_CONFIMATION',
  (value: boolean | undefined) => ({ value }),
)
