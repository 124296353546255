import React from 'react'
import styles from './ExclusiveOfferScreen.module.scss'
import {
  Flex,
  Input,
  Text,
  Transition,
  VStack,
} from '@sendoutcards/quantum-design-ui'
import { useUpdateAccount } from 'src/react_query'
import {
  useActions,
  useCallback,
  useDebounce,
  useEmailValidation,
  useState,
} from 'src/hooks'
import { sanitizeUsername } from 'src/helpers/sanitizeUsername'
import useUsernameValidation from 'src/hooks/useUsernameValidation'
import { Icon } from 'src/design_system/atoms/icons/components/Icon'
import AccountSponsorSelector from './AccountSponsorSelector'

const ExclusiveOfferScreen = (props: {
  onFinishedJoin?: () => void
  onClose?: () => void
}) => {
  const { onFinishedJoin, onClose } = props
  const updateAccountMutation = useUpdateAccount()
  const actions = useActions()
  const [email, setEmail] = useState('')
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [sponsor, setSponsor] = useState<string | undefined>()
  const [isLoading, setIsLoading] = useState(false)
  const [updateAccountFailureMessage, setUpdateAccountFailureMessage] =
    useState<string>()

  const debouncedEmail = useDebounce(email, 500)
  const emailValidation = useEmailValidation(debouncedEmail)

  const handleUsernameChange = (value: string) => {
    setUsername(sanitizeUsername(value))
  }
  const debouncedUsername = useDebounce(username, 500)
  const usernameValidation = useUsernameValidation(debouncedUsername)

  const onSubmit = async () => {
    try {
      setIsLoading(true)
      if (
        !usernameValidation.isValid ||
        usernameValidation.isLoading ||
        !emailValidation.isValid ||
        emailValidation.isLoading
      ) {
        return
      }
      const {
        updateAccount: { account: updatedAccount },
      } = await updateAccountMutation.mutateAsync({
        account: {
          username,
          password,
          email,
          ...(sponsor
            ? {
                sponsor,
              }
            : {}),
        },
        joinReward: '3daytrial',
      })
      actions.updatedAccount(updatedAccount)
      actions.setShouldDisplayJoinConfirmation(true)
      onFinishedJoin?.()
    } catch (error) {
      setUpdateAccountFailureMessage(
        error?.toString() ?? 'Failed to update account.',
      )
    } finally {
      setIsLoading(false)
    }
  }

  const handleSponsorChanges = useCallback(
    (userId: string, genealogyId?: string) => {
      setSponsor(userId)
    },
    [],
  )

  if (isLoading) {
    return <Transition title={'Creating your Account'} isLoading={isLoading} />
  }

  return (
    <div className={styles.backgroundContainer}>
      <div
        style={{
          position: 'absolute',
          top: 20,
          height: '50%',
          width: '100%',
          backgroundImage: `url(https://soc-website-public.s3.amazonaws.com/static/soc-website/images/manual/PremiumCardCoversGrid.png)`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      />
      <div className={styles.container}>
        <Flex
          flexDirection={'row'}
          width={'100%'}
          justifyContent={'end'}
          minHeight={'12px'}
        >
          {onClose !== undefined && (
            <button className={styles.closeButton} onClick={() => onClose()}>
              <Icon name={'cancel'} color="black" size={12} />
            </button>
          )}
        </Flex>

        <div className={styles.offerCard}>
          <div className={styles.cardImageContainer}>
            <img
              className={styles.cardImageContainer.img}
              src={
                'https://soc-website-public.s3.amazonaws.com/static/soc-website/images/manual/digitalAndPrintedSends.png'
              }
              alt="GoodVibesCardFrontPanel"
            />
          </div>
          <Text
            type={'subtitle'}
            content={'Set Up Your Account'}
            alignment="center"
            color={'primaryHeading'}
            weight="bold"
          />
          <Text type="caption" alignment="center" weight="semiBold">
            Finish setting up your account to continue sending more ACTs of
            kindness to those that need to hear from you.
          </Text>
          <div className={styles.loginForm}>
            <VStack gap="x2_5">
              <Input
                icon="envelope"
                placeholder="Email"
                onChange={setEmail}
                value={email}
                type={'text'}
                message={
                  emailValidation.errorMessage && email
                    ? {
                        type: 'danger',
                        content: emailValidation.errorMessage,
                        icon: 'information',
                      }
                    : undefined
                }
                isFullWidth={true}
                isLoading={emailValidation.isLoading}
              />
              <Input
                icon="user"
                placeholder="Username"
                onChange={handleUsernameChange}
                value={username}
                type={'text'}
                message={
                  !usernameValidation.isValid &&
                  !!username &&
                  !usernameValidation.isLoading
                    ? { type: 'danger', content: 'Username is already taken.' }
                    : undefined
                }
                isFullWidth={true}
                isLoading={usernameValidation.isLoading}
              />
              <Input
                icon="lock"
                placeholder="Password"
                type="password"
                onChange={setPassword}
                value={password}
                message={
                  updateAccountFailureMessage
                    ? { type: 'danger', content: updateAccountFailureMessage }
                    : undefined
                }
                isFullWidth={true}
              />
              <VStack gap={'x0'}>
                <Text
                  type={'footnote'}
                  weight="semiBold"
                  content={'Sponsor'}
                  style={{ marginLeft: '4px' }}
                />
                <AccountSponsorSelector
                  isAffiliateJoining={false}
                  handleSponsorChanges={handleSponsorChanges}
                />
              </VStack>
            </VStack>
            <button
              className={styles.tryPremiumButton}
              onClick={() => onSubmit()}
            >
              <Text
                type={'caption'}
                weight="semiBold"
                content={'Create Account'}
                style={{ marginLeft: '8px', color: 'white' }}
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ExclusiveOfferScreen
