import React from 'react'
import {
  ConfirmDialog,
  Flex,
  Image,
  LoadingSpinner,
  Spacer,
  Text,
} from '@sendoutcards/quantum-design-ui'
import { Button } from 'src/design_system/components/Button/Button'
import {
  CardFragment,
  Contact,
  DigitalCardOrder,
} from 'src/graphql/generated/graphql'
import { useActions, useMutations, useSelector, useState } from 'src/hooks'
import {
  useDeleteDigitalCard,
  useDigitalCardOrderCreate,
  useDigitalCardRecipientCreate,
  useDigitalCardUpdate,
  useMarketingContent,
} from 'src/react_query'
import styles from './prepareAct.module.scss'
import ContactSearch from '../ContactSearch/ContactSearch'
import CardCostTag from './CardCostTag'
import DialogHeader from '../ActSetup/DialogHeader'
import ActCardGraphic from '../ActCardGraphic/ActCardGraphic'
import { Dialog } from 'src/design_system/molecules/dialog/Dialog'

type PrepareActProps = {
  card: CardFragment
  hasCreatedDigitalOrder: boolean
  onBack: () => void
  onPreparationComplete: (digitalOrder: DigitalCardOrder) => void
}

const PrepareAct: React.FC<PrepareActProps> = props => {
  const { card, hasCreatedDigitalOrder, onBack, onPreparationComplete } = props

  const actions = useActions()
  const mutations = useMutations()
  const { data: marketingContent } = useMarketingContent()
  const deleteDigitalCard = useDeleteDigitalCard()
  const createDigitalCardRecipientMutation = useDigitalCardRecipientCreate()
  const updateDigitalCardMutation = useDigitalCardUpdate()
  const digitalCardOrderCreateMutation = useDigitalCardOrderCreate()
  const isMobile = useSelector(state => state.window.width < 799)

  const [title, setTitle] = useState(card.detailedSendableCard?.title ?? '')
  const [recipientName, setRecipientName] = useState('')
  const [contact, setContact] = useState<Contact | undefined>(undefined)
  const [
    shouldShowPrintedCardConfirmModal,
    setShouldShowPrintedCardConfirmModal,
  ] = useState(false)
  const [isConvertingToPrinted, setIsConvertingToPrinted] = useState(false)
  const isRecipientSelected = !!recipientName || !!contact

  const isCreatingActOrder =
    updateDigitalCardMutation.isLoading ||
    createDigitalCardRecipientMutation.isLoading ||
    digitalCardOrderCreateMutation.isLoading

  const convertToPrintedModalTitle =
    marketingContent?.convertToPrintedModalTitle.content
  const convertToPrintedModalContent =
    marketingContent?.convertToPrintedModalContent.content

  const createActOrder = async () => {
    if (isRecipientSelected) {
      const digitalCardPk = card.digitalCard?.pk
      if (digitalCardPk) {
        await updateDigitalCardMutation.mutateAsync({
          digitalCardPk: digitalCardPk,
          input: { shareMessageTitle: title },
        })
        await createDigitalCardRecipientMutation.mutateAsync({
          digitalCardPk: digitalCardPk,
          ...(contact
            ? { contactUuid: contact.id }
            : { firstName: recipientName }),
        })
        const digitalOrder = await digitalCardOrderCreateMutation.mutateAsync({
          digitalCardPk: digitalCardPk,
        })

        onPreparationComplete(digitalOrder)
      }
    }
  }

  const handleContactSelect = (selectedContact?: Contact) => {
    setContact(selectedContact)
  }

  const handleContactSearchRecipientChanges = (value: string) => {
    setRecipientName(value)
    if (contact) {
      setContact(undefined)
    }
  }

  const goToContactSearch = () => {
    if (document) {
      const input = document.querySelector('#data_input_cs') as HTMLInputElement
      input?.focus()
    }
  }

  const handleOnConvertToPrinted = async () => {
    if (!card.digitalCard) return
    setIsConvertingToPrinted(true)
    const {
      createOrder: { order },
    } = await mutations.createOrder({
      order: { lines: [{ card: card.id }] },
    })
    await deleteDigitalCard.mutateAsync({ id: card.digitalCard.pk })
    actions.openOrder(order.id)
  }

  const actionButtonSharedStyles: React.CSSProperties = {
    marginTop: 'auto',
    marginBottom: '1rem',
    width: '100%',
    padding: '0 1rem',
    zIndex: 10,
    position: 'relative', // Correctly typed
  }

  const mobileActionStyles: React.CSSProperties = {
    position: 'fixed', // Correctly typed
    bottom: 0,
    left: 0,
  }

  const actionContainerStyles: React.CSSProperties = isMobile
    ? { ...actionButtonSharedStyles, ...mobileActionStyles }
    : actionButtonSharedStyles

  const mobileFixedActionContainerHeight = isMobile ? '100px' : 'initial'

  const convertToPrintedButtonStyles: React.CSSProperties = {
    display: 'flex',
    fontFamily: 'Montserrat, sans-serif',
    fontWeight: 600,
    color: '#404040',
    fontSize: '16px',
    textDecoration: 'underline',
    cursor: 'pointer',
    justifyContent: 'center',
    marginTop: '8px',
  }

  if (shouldShowPrintedCardConfirmModal) {
    return (
      <ConfirmDialog
        isOpen={true}
        title={convertToPrintedModalTitle ?? 'Convert to a printed card'}
        description={convertToPrintedModalContent ?? ''}
        accept={{
          title: 'Continue',
          onClick: () => {
            handleOnConvertToPrinted()
            setShouldShowPrintedCardConfirmModal(false)
          },
        }}
        decline={{
          title: 'Cancel',
          onClick: () => setShouldShowPrintedCardConfirmModal(false),
        }}
        requireScroll={false}
      />
    )
  }

  if (isConvertingToPrinted) {
    return (
      <Dialog
        mobileBreakPointOverride={799}
        maxWidth={isMobile ? undefined : '360px'}
        mobileOpenHeight="0px"
        isOpen={true}
        onClose={() => {
          // what to do here
        }}
        outerWrapperStyle={{ display: 'flex', flexDirection: 'column' }}
        wrapperStyle={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: '1',
          overflowX: 'auto',
        }}
        shouldHideTitleBar={true}
        padding="20px"
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '20px',
          }}
        >
          <Image
            isActive
            image={{ url: card.frontPreviewUrl }}
            width={card.isHorizontal ? '210px' : '150px'}
            height={card.isHorizontal ? '150px' : '210px'}
          />
          <Text
            content="Generating Printed Card"
            type="largeBody"
            color="primaryHeading"
            weight="semiBold"
          />
          <LoadingSpinner size="medium" />
        </div>
      </Dialog>
    )
  }

  return (
    <Dialog
      mobileBreakPointOverride={799}
      isOpen={true}
      onClose={onBack}
      padding={isMobile ? '20px 10px 40px' : undefined}
      outerWrapperStyle={{
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: mobileFixedActionContainerHeight,
      }}
      wrapperStyle={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: '1',
        overflowX: 'auto',
      }}
      shouldHideTitleBar={true}
      mobileOpenHeight="0px"
    >
      <DialogHeader onBack={onBack} />
      {isMobile && (
        <div
          style={{
            width: '100%',
            marginTop: '24px',
            marginBottom: '24px',
          }}
        >
          <CardCostTag
            cardCost={undefined}
            title={card.detailedSendableCard?.title}
            cardPreviewUrl={card.frontPreviewUrl}
          />
        </div>
      )}
      <div
        style={{
          display: 'flex',
          columnGap: !isMobile ? '3rem' : undefined,
          marginTop: !isMobile ? '2rem' : undefined,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly',
          }}
        >
          {!isMobile && (
            <>
              <Spacer space="x2" />
              <ActCardGraphic
                isHorizontal={card.isHorizontal ?? false}
                cardPreviewUrl={card.frontPreviewUrl}
                shouldShowGradientBackground={false}
                shouldShowCellphone={true}
                shouldShowEnvelope={false}
              />
            </>
          )}
        </div>
        <div style={{ minWidth: '365px' }}>
          <div
            style={{
              width: '100%',
              padding: '0.5rem 1rem 0',
              marginTop: '0.5rem',
            }}
          >
            <Text
              type="caption"
              content="Who do you want to send this to?"
              alignment="center"
              style={{ fontSize: '16px', fontWeight: 700 }}
            />
            <div>
              <ContactSearch
                onContactSelect={handleContactSelect}
                onChange={handleContactSearchRecipientChanges}
                currentlySelectedContactId={contact?.id}
              />
            </div>
            <div>
              <Flex width="100%" flexDirection="column" rowGap={'x1'}>
                <Text
                  type="caption"
                  content="Subject"
                  weight="semiBold"
                  outset={{ top: 'x2' }}
                />
                <input
                  type="text"
                  className={styles.input}
                  value={title}
                  onChange={e => setTitle(e.target.value)}
                  autoCorrect="off"
                  spellCheck="false"
                  autoComplete="off"
                />
                <Spacer space="x1" />
              </Flex>
            </div>
            <div style={actionContainerStyles}>
              <div id="controls3DCard" style={{ marginBottom: '2rem' }} />
              <Button
                title={
                  isCreatingActOrder
                    ? 'Creating Order...'
                    : isRecipientSelected
                      ? 'Save & Share'
                      : 'Add a Recipient'
                }
                onClick={
                  isRecipientSelected ? createActOrder : goToContactSearch
                }
                width="100%"
                height="64px"
                borderRadius="1rem"
                backgroundColor={isRecipientSelected ? '#404040' : 'gray'}
                isDisabled={isCreatingActOrder}
              />
              {!hasCreatedDigitalOrder && (
                <div
                  onClick={() => setShouldShowPrintedCardConfirmModal(true)}
                  style={convertToPrintedButtonStyles}
                >
                  Convert To Printed Card
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export default PrepareAct
