// this needs to be loaded before React
import React from 'react'
import {
  ConfirmDialog,
  QuantumThemeWrapper,
  Sheet,
  StaticNavigation,
} from '@sendoutcards/quantum-design-ui'

// @src imports
import Router from 'src/routes/Router'
import {
  DefaultError,
  HelpMenu,
  SideBar,
  SuspenseBoundary,
  Transition,
} from 'src/chrome'
import { BaseRoute } from 'src/routes/BaseRoute'
import { JoinModal } from 'src/user/components'
import {
  useActions,
  useCallback,
  useConvertToRep,
  useEffect,
  useFlag,
  useIntercomEffect,
  usePersistedUserData,
  useProfitWellEffect,
  useQueryParams,
  useRedirectEffect,
  useRoute,
  useSelector,
  useState,
  useUpdateSponsorEffect,
  useVertical,
} from 'src/hooks'
import getEmailVerificationLabel from 'src/helpers/getEmailVerificationLabel'
import { loadSpace } from '@usersnap/browser'
// tslint:disable-next-line: no-import-side-effect
import 'normalize.css'
// tslint:disable-next-line: no-import-side-effect
import 'src/app/styles/resets.scss'
import styles from 'src/chrome/styles/Chrome.module.scss'
import { DashboardRoute } from 'src/dashboard/routes/DashboardRoute'
import { GiftStoreRoute } from 'src/gift_store/routes/GiftStoreRoute'
import { PromoRoute } from 'src/promo/routes/PromoRoute'
import PhoneNumberVerification from 'src/onboarding/components/PhoneNumberVerification'
import Result, { success } from 'src/utils/Result'
import ProgressiveProfileActions from 'src/onboarding/components/ProgressiveProfileActions'
import PlanUpsale from 'src/plan_upsale/PlanUpsale'
import GiftStore from 'src/gift_store/containers/GiftStore'
import FeaturedCardsGrid from 'src/catalog/components/FeaturedCardsGrid/FeaturedCardsGrid'
import { DuplicateOrInvalidEmailBlocker } from 'src/email_verification/DuplicateOrInvalidEmailBlocker/DuplicateOrInvalidEmailBlocker'
import getLocalStorage from 'src/helpers/getLocalStorage'
import Favicon from 'src/chrome/Favicon/Favicon'
import { PricingRoute } from 'src/marketing/plans/routes/PricingRoute'
import { PricingPage } from 'src/pricing_page/PricingPage'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { CheckoutContextProvider } from 'src/saleor/context/CheckoutContext'
import StaticNavAccountSection from 'src/chrome/StaticNavAccountSection/StaticNavAccountSection'
import { dropDownMenuLinks } from 'src/dashboard/containers/Dashboard/Dashboard'
import { navLinks, navLogo } from 'src/dashboard/navigationData'
import { OrdersRoute } from 'src/orders/routes/OrdersRoute'
import { RouteType } from '@sendoutcards/core/lib/routes/utilities'
import Orders from 'src/orders/containers/Orders'
import { useSaleorAuth } from 'src/react_query/queries/hooks'
import { useForceAccountRefresh } from 'src/hooks/useForceAccountRefresh'
import { useAccountQuery, useCreateCard, useFlags } from 'src/react_query'
import {
  CreateCardMutationVariables,
  UserType,
  VerifyPhoneNumberSuccessType,
} from 'src/graphql/generated/graphql'
import { SignInRoute } from 'src/sign_in/routes/SignIn'
import SignIn from 'src/sign_in/SignIn'
import { SignUpRoute } from 'src/sign_up/routes/SignUp'
import SignUp from 'src/sign_up/SignUp'
import { ResetPasswordRoute } from 'src/reset_password/routes/ResetPassword'
import ResetPassword from 'src/reset_password/ResetPassword'
import { SubmitRequestedContactRoute } from 'src/contacts/routes/SubmitRequestedContact'
import SubmitRequestedContact from 'src/contacts/containers/SubmitRequestedContact'
import { ActRoute } from 'src/act/routes/ActRoute'
import Act from 'src/act/containers/Act'
import ExclusiveOfferScreen from 'src/onboarding/components/ExclusiveOfferScreen'
import { CatalogRoute } from 'src/catalog/routes/CatalogRoute'
import { ExclusiveOfferConfirmationScreen } from 'src/onboarding/components/ExclusiveOfferConfirmationScreen'

type UnAuthedRouteViews = {
  [key: string]: React.ReactElement
}

type CardCreationState =
  | 'isNotCreatingCard'
  | 'isCreatingCard'
  | 'didCreateCard'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY ?? '')

const App: React.FC = () => {
  const route = useRoute()
  const [cardCreationState, setCardCreationState] =
    useState<CardCreationState>('isNotCreatingCard')
  const [isSendingFreeCard, setIsSendingFreeCard] = useState(false)
  const [isBulkOrderPromo, setIsBulkOrderPromo] = useState(false)
  const [isUserSnapInitialized, setIsUserSnapInitialized] = useState(false)
  const queryParams = useQueryParams()
  useSaleorAuth()

  const {
    account,
    controlData,
    phoneVerification,
    _persist: { rehydrated: isRehydrated },
  } = usePersistedUserData()

  const isEnabledUseAccountQuery =
    !!controlData.token && (!account || !controlData.isLoggedIn)
  const { isMobile } = useSelector(state => state.window)

  const actions = useActions()
  const storage = getLocalStorage()
  const vertical = useVertical()
  const createCardMutation = useCreateCard()
  const { data: fetchedAccount } = useAccountQuery({
    enabled: isEnabledUseAccountQuery,
    suspense: true,
  })
  const forceAccountRefresh = useForceAccountRefresh()
  const { data: flags } = useFlags()

  const { actDisplay: hasAct } = useFlag(flags ?? [])
  const isSparse = !account?.username

  const isSidebarEnabled = !BaseRoute.hideSidebar(route)

  const [shouldPromptCookiesMessage, setShouldPromptCookiesMessage] = useState(
    !storage ? true : false,
  )

  const peContainerStyles: React.CSSProperties = !isSidebarEnabled
    ? { height: '100vh' }
    : {}

  const canShowProgressiveDialog =
    !isSendingFreeCard &&
    !isBulkOrderPromo &&
    !isSparse &&
    route.subroute.path !== '/pricing' &&
    !(
      route.subroute.path === '/dashboard' &&
      route.subroute.subroute.path === '/pricing'
    )

  useIntercomEffect()

  useConvertToRep()

  // Usersnap
  useEffect(() => {
    if (isUserSnapInitialized || !hasAct) return

    const userSnapKey = '739fc43b-136b-4963-abc5-51eadee3562e'

    const initializeUserSnap = async () => {
      try {
        const api = await loadSpace(userSnapKey)
        api.init()
        setIsUserSnapInitialized(true)
      } catch (error) {
        console.error('Usersnap initialization failed:', error)
      }
    }

    initializeUserSnap()
  }, [isUserSnapInitialized, hasAct])

  const createPromoCard = useCallback(
    async ({
      card,
      sendableCard,
      type,
      paperType,
    }: CreateCardMutationVariables) => {
      try {
        setCardCreationState('isCreatingCard')
        const { createCard } = await createCardMutation.mutateAsync({
          card,
          sendableCard,
          type,
          paperType,
        })
        setIsBulkOrderPromo(queryParams.bulkOrderPromo ? true : false)
        setCardCreationState('didCreateCard')
        actions.openCard(createCard.card.id)
      } catch (error) {
        console.error(error)
      }
    },
    [actions, createCardMutation, queryParams],
  )

  useEffect(() => {
    if (fetchedAccount && isEnabledUseAccountQuery) {
      actions.loadedUser(Result(fetchedAccount))
    }
  }, [
    controlData.token,
    account,
    actions,
    fetchedAccount,
    isEnabledUseAccountQuery,
  ])

  useEffect(() => {
    // Get refreshed account after updatedAccount or needsRefreshedAccount actions
    if (controlData.needsAccountRefresh) {
      setTimeout(() => forceAccountRefresh(), 60_000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controlData.needsAccountRefresh])

  useEffect(() => {
    // Get refreshed account at first load when logged in
    forceAccountRefresh()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // PostCard promo
  useEffect(() => {
    if (account && queryParams.postcardPromo) {
      setIsSendingFreeCard(account.isQualifiedForFirstFreeCard)
      actions.openCardCollection('270') // Hardcoded Collection for this path
      actions.removeRouteArg('postcardPromo')
    }
  }, [account, queryParams, actions])

  // BulkOrder Promo path
  useEffect(() => {
    const path = route.subroute.path

    if (
      account &&
      path === PromoRoute.path &&
      queryParams.bulkOrderPromo &&
      cardCreationState === 'isNotCreatingCard'
    ) {
      createPromoCard({
        sendableCard: queryParams.bulkOrderPromo.cardId,
        type: queryParams.bulkOrderPromo.cardType,
        paperType: queryParams.bulkOrderPromo.paperType,
      })
    }
  }, [
    account,
    queryParams.bulkOrderPromo,
    createPromoCard,
    cardCreationState,
    route,
  ])

  useEffect(() => {
    const path = route.subroute.path

    if (
      path !== DashboardRoute.path &&
      phoneVerification.unverifiedAccountsFound &&
      account
    ) {
      actions.openAccount()
    }
  }, [
    account,
    actions,
    phoneVerification.unverifiedAccountsFound,
    route.subroute.path,
  ])

  useUpdateSponsorEffect()
  useProfitWellEffect()
  useRedirectEffect()

  useEffect(() => {
    if (queryParams.isJoiningAffiliate && queryParams.package === 'pro') {
      actions.openUnauthPricing()
    }
  }, [queryParams.isJoiningAffiliate, queryParams.package, actions])

  const emailVerificationType = account?.labels
    ? getEmailVerificationLabel(account.labels)
    : undefined

  if (emailVerificationType) {
    return (
      <DuplicateOrInvalidEmailBlocker
        email={account?.email ?? ''}
        type={emailVerificationType}
      />
    )
  }

  if (
    !isRehydrated ||
    (queryParams.bulkOrderPromo && cardCreationState === 'isCreatingCard')
  ) {
    return <div />
  }

  if (shouldPromptCookiesMessage) {
    return (
      <ConfirmDialog
        isOpen={true}
        title={`Welcome to ${vertical.name}`}
        description={`We have detected that your browser is blocking cookies. For a better experience, please, enable cookies.`}
        accept={{
          title: 'OK',
          onClick: () => setShouldPromptCookiesMessage(false),
        }}
      />
    )
  }

  const unAuthedRouteViews = (route: RouteType): UnAuthedRouteViews => ({
    [GiftStoreRoute.path]: (
      <GiftStore route={route.subroute as GiftStoreRoute} />
    ),
    [PricingRoute.path]: <PricingPage route={route.subroute as PricingRoute} />,
    [OrdersRoute.path]: <Orders route={route.subroute as OrdersRoute} />,
    [SubmitRequestedContactRoute.path]: (
      <SubmitRequestedContact
        route={route.subroute as SubmitRequestedContactRoute}
      />
    ),
    [ActRoute.path]: <Act route={route.subroute as ActRoute} />,
    [SignInRoute.path]: <SignIn route={route.subroute as SignInRoute} />,
    [SignUpRoute.path]: <SignUp route={route.subroute as SignUpRoute} />,
    [ResetPasswordRoute.path]: (
      <ResetPassword route={route.subroute as ResetPasswordRoute} />
    ),
  })

  const isStaticRoute =
    !account &&
    Object.keys(unAuthedRouteViews(route)).includes(route.subroute?.path ?? '')

  const isActRoute = route.subroute.path === ActRoute.path

  // Exposes gift store, pricing & orders pages to unauthenticated users
  if (isStaticRoute) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100dvh',
        }}
      >
        {!isActRoute && (
          <div
            style={{ position: 'sticky', zIndex: 400, width: '100%', top: 0 }}
          >
            <StaticNavigation
              links={navLinks()}
              logo={navLogo}
              accountSection={<StaticNavAccountSection />}
              menuDropdownLinks={dropDownMenuLinks()}
            />
          </div>
        )}
        {unAuthedRouteViews(route)[route.subroute?.path ?? '']}
        {controlData.shouldShowPhoneVerification && (
          <Sheet
            isOpen={true}
            zIndex={1000}
            backgroundElement={<FeaturedCardsGrid />}
          >
            <PhoneNumberVerification
              loginPhoneNumber={phoneVerification.loginPhoneNumber}
              sponsorGenealogyIdOrSlug={queryParams.sponsor}
              onSuccess={({ verifiedAccount, successType }) => {
                actions.loginDone(success(verifiedAccount))
                if (
                  successType ===
                    VerifyPhoneNumberSuccessType.CreatedNewAccount ||
                  VerifyPhoneNumberSuccessType.SignedIntoExistingAccount
                ) {
                  setIsSendingFreeCard(true)
                  if (!queryParams.postcardPromo) {
                    actions.openCatalog()
                  }
                } else {
                  actions.openAccount()
                }
              }}
            />
          </Sheet>
        )}
      </div>
    )
  }

  if (
    !account &&
    queryParams.isJoiningAffiliate &&
    queryParams.package === 'pro'
  ) {
    return (
      <Transition
        message={'Loading ...'}
        css={{ width: '100%', height: '100%' }}
      />
    )
  }

  if (
    !account ||
    (queryParams.isJoiningAffiliate &&
      account &&
      account.type !== UserType.Gsa &&
      queryParams.package !== 'pro')
  ) {
    return controlData.token && !queryParams.isJoiningAffiliate ? (
      <Transition
        message={'Loading your account...'}
        css={{ width: '100%', height: '100%' }}
      />
    ) : controlData.shouldShowPhoneVerification ? (
      <Sheet isOpen={true} backgroundElement={<FeaturedCardsGrid />}>
        <PhoneNumberVerification
          loginPhoneNumber={phoneVerification.loginPhoneNumber}
          sponsorGenealogyIdOrSlug={queryParams.sponsor}
          onSuccess={({ verifiedAccount, successType }) => {
            actions.loginDone(success(verifiedAccount))
            if (
              successType === VerifyPhoneNumberSuccessType.CreatedNewAccount ||
              VerifyPhoneNumberSuccessType.SignedIntoExistingAccount
            ) {
              setIsSendingFreeCard(true)
              if (!queryParams.postcardPromo) {
                actions.openCatalog()
              }
            } else {
              actions.openAccount()
            }
          }}
        />
      </Sheet>
    ) : (
      <JoinModal
        initialAction={
          queryParams.join ||
          (queryParams.isJoiningAffiliate && !account) ||
          (queryParams.isJoiningPromo && !account)
            ? 'join'
            : 'login'
        }
        isJoiningAffiliate={!!queryParams.isJoiningAffiliate}
      />
    )
  }

  if (
    account &&
    queryParams.promoPlanId &&
    account.plan.id !== queryParams.promoPlanId &&
    queryParams.planSelected
  ) {
    return <PlanUpsale selectedPlanId={queryParams.promoPlanId} />
  }

  if (account && controlData.shouldShowPhoneVerification) {
    return (
      <Sheet isOpen={true} backgroundElement={<FeaturedCardsGrid />}>
        <PhoneNumberVerification
          loginPhoneNumber={phoneVerification.loginPhoneNumber}
          sponsorGenealogyIdOrSlug={undefined}
          onSuccess={({ verifiedAccount }) => {
            actions.loginDone(success(verifiedAccount))
          }}
        />
      </Sheet>
    )
  }

  if (
    route.subroute.path === CatalogRoute.path &&
    account &&
    hasAct &&
    !account.isQualifiedForFirstFreeCard &&
    isSparse
  ) {
    return <ExclusiveOfferScreen />
  }

  if (controlData.shouldDisplayJoinConfirmation) {
    return (
      <ExclusiveOfferConfirmationScreen
        onConfirm={() => {
          actions.setShouldDisplayJoinConfirmation(undefined)
          // Goes to Updsale Unlimited ACTs
        }}
        onClose={() => {
          actions.setShouldDisplayJoinConfirmation(undefined)
        }}
      />
    )
  }

  return (
    <div
      style={peContainerStyles}
      className={`peContainer ${!isSidebarEnabled ? styles.hideSidebar : ''} ${
        !account && styles.noSidebar
      }`}
    >
      {isSidebarEnabled && <SideBar />}
      {!isMobile && <HelpMenu />}
      <Router />
      {canShowProgressiveDialog && <ProgressiveProfileActions />}
    </div>
  )
}

const AppWrapper = () => {
  const { theme } = useVertical()
  const message = useSelector(
    state => state.graphql.blockingMutationTransitionMessage,
  )
  return (
    <QuantumThemeWrapper theme={theme}>
      <SuspenseBoundary unresolved={<Transition />} failure={DefaultError}>
        <div>
          <Favicon />
          <Elements stripe={stripePromise}>
            <CheckoutContextProvider>
              <App />
            </CheckoutContextProvider>
          </Elements>
          {message && <Transition message={message} />}
        </div>
      </SuspenseBoundary>
    </QuantumThemeWrapper>
  )
}

export default AppWrapper
