import { useAccount, useMemo, useSubscriptions } from 'src/hooks'
import {
  getTotalGiftDiscounts,
  getOrderCardBaseCost,
  getTotalPointsDiscount,
  getPrice,
  getShippingInfo,
} from 'src/orders/helpers'
import { DiscountItem } from 'src/dashboard/components/TotalDiscounts/TotalDiscounts'
import { CardSendType } from 'src/graphql/generated/graphql'
import { OrderWithPartialLines } from 'src/redux/reducers/orders'

interface UseDiscountBreakdownReturn {
  discountItems: DiscountItem[]
}

const useGetDiscountBreakDown = (
  order?: OrderWithPartialLines,
): UseDiscountBreakdownReturn => {
  const account = useAccount()
  const subscription = useSubscriptions()
  const shippingInfo = getShippingInfo(account.giftShippingDiscount, order)
  const recipientCount =
    order && order.contacts.length > 0 ? order.contacts.length : 1
  const totalGiftDiscounts = getTotalGiftDiscounts(recipientCount, order) ?? 0
  const cardBaseCost = getOrderCardBaseCost(order)
  const { systemSends, heartfeltSystemSends } = account

  const pointsDiscount = getTotalPointsDiscount(order)

  const pointsDiscountItem =
    pointsDiscount > 0
      ? ({
          title: 'Points Discount',
          value: pointsDiscount,
          shouldNotFormat: true,
        } as DiscountItem)
      : undefined

  const orderSystemCards = getPrice(order?.cost.entries, 'System Sends')?.amount

  const employeeSendsDiscounts = getPrice(
    order?.cost.entries,
    'Employee Sends',
  )?.amount

  const orderHeartfeltSystemCards = getPrice(
    order?.cost.entries,
    'Card Sends',
  )?.amount

  const systemCardsDiscounts =
    orderSystemCards && orderHeartfeltSystemCards
      ? orderSystemCards + orderHeartfeltSystemCards
      : (orderSystemCards ?? orderHeartfeltSystemCards)

  // System cards discounts are determined by how many system card tokens will
  // be used times the card base cost
  const systemCards =
    cardBaseCost && systemCardsDiscounts && systemCardsDiscounts > 0
      ? ({
          title: `${systemSends + heartfeltSystemSends} Free System Cards`,
          subtitle: ` applying  ${systemCardsDiscounts} of ${
            systemSends + heartfeltSystemSends
          }`,
          value: systemCardsDiscounts * cardBaseCost,
        } as DiscountItem)
      : undefined

  // Heartfelt cards can only be sent to one person, thus the discount should always be
  // the card base cost.
  const cardSendDiscounts =
    order?.sendType === CardSendType.Heartfelt &&
    subscription.hasAvailableHeartfeltSendTokens
      ? ({
          title: 'Heartfelt Discount',
          value: cardBaseCost,
        } as DiscountItem)
      : undefined

  const employeeSends =
    cardBaseCost && employeeSendsDiscounts && employeeSendsDiscounts > 0
      ? ({
          title: `Free Employee Cards`,
          value: employeeSendsDiscounts * cardBaseCost,
        } as DiscountItem)
      : undefined

  const premiumCardDiscounts = useMemo(() => {
    if (!order) return undefined
    const premiumCardsLines = order.lines.filter(
      line =>
        line.card &&
        line.card.detailedSendableCard &&
        line.card.detailedSendableCard.isPremium,
    )
    if (premiumCardsLines.length === 0) return undefined

    const upgradesCost = premiumCardsLines
      .map(line => getPrice(line?.card?.cost.entries, 'Card Upgrades'))
      .filter(line => line !== undefined)
      .reduce((acc, line) => {
        if (!line) return acc
        return acc + line.amount
      }, 0)

    const upgradesDiscountedCost = premiumCardsLines
      .map(line => getPrice(line?.cost?.entries, 'Card Upgrades'))
      .filter(line => line !== undefined)
      .reduce((acc, line) => {
        if (!line) return acc
        return acc + line.amount
      }, 0)

    return {
      title: 'Free Premium Discount',
      value: upgradesCost - upgradesDiscountedCost,
    } as DiscountItem
  }, [order])

  const giftDiscounts =
    totalGiftDiscounts > 0
      ? ({
          title: 'Discount on Gifts',
          info: 'Discount is calculated based on product and subscription type.',
          value: totalGiftDiscounts,
        } as DiscountItem)
      : undefined

  const shippingDiscounts = shippingInfo.hasShippingDiscount
    ? ({
        title: 'Discount on Shipping',
        info: 'Discount is calculated based on product and subscription type.',
        value: shippingInfo.shippingDiscountAmount,
      } as DiscountItem)
    : undefined

  const discountItems = [
    ...(pointsDiscountItem ? [pointsDiscountItem] : []),
    ...(systemCards ? [systemCards] : []),
    ...(cardSendDiscounts ? [cardSendDiscounts] : []),
    ...(employeeSends ? [employeeSends] : []),
    ...(giftDiscounts ? [giftDiscounts] : []),
    ...(shippingDiscounts ? [shippingDiscounts] : []),
    ...(premiumCardDiscounts ? [premiumCardDiscounts] : []),
  ]

  return { discountItems }
}

export default useGetDiscountBreakDown
