import React from 'react'
import {
  CardFragment,
  ColorFragment,
  DigitalCardOrder,
} from 'src/graphql/generated/graphql'
import {
  useAccount,
  useActions,
  useQueryFutures,
  useSelector,
  useState,
} from 'src/hooks'
import PrepareAct from '../PrepareAct/PrepareAct'
import { ActCheckout } from '../ActCheckout/ActCheckout'
import { useDigitalCardOrderPay } from 'src/react_query/mutations/hooks'
import DialogHeader from './DialogHeader'
import { LEGACY_SITE_URL } from 'src/app/api'
import { ActRoute } from 'src/act/routes/ActRoute'
import { ActConfigRoute } from 'src/act/routes/ActConfiguration'
import { ActCheckoutRoute } from 'src/act/routes/ActCheckout'
import { useGetCard } from 'src/react_query'
import { ActSuccessRoute } from 'src/act/routes/ActSuccess'
import {
  LoadingSpinner,
  Text,
  Image,
  Flex,
} from '@sendoutcards/quantum-design-ui'
import { Dialog } from 'src/design_system/molecules/dialog/Dialog'
import ExclusiveOfferScreen from 'src/onboarding/components/ExclusiveOfferScreen'
import { ShareScreen } from '../ShareScreen/ShareScreen'
import { EditorCardColorBackground } from '../ShareScreen/components/EditorCardColorBackground'
import { getDesignColorsBySendableCardID } from 'src/legacy_graphql'
import { DesignColors } from 'src/editor/api'
import { selectedVariationId } from 'src/redux/selectors/editor'
import { factoryFullBleeds } from 'src/editor/EditorCard'

type ActSetupProps = {
  route: ActRoute
  cardId: string
}

const ActSetup: React.FC<ActSetupProps> = props => {
  const { route, cardId } = props
  const actions = useActions()
  const account = useAccount()
  const isMobile = useSelector(state => state.window.width < 500)
  const isSparse = !account.username

  const baseURL = LEGACY_SITE_URL

  const digitalCardOrderPayMutation = useDigitalCardOrderPay()
  const getCardQuery = useGetCard({ id: cardId }, { suspense: true })
  const card = getCardQuery.data?.card

  if (!card) {
    throw new Error('Card not found!')
  }

  const [isLoading, setIsLoading] = useState(false)
  const [digitalOrder, setDigitalOrder] = useState<
    DigitalCardOrder | undefined
  >(undefined)

  const digitalCardToken =
    card?.digitalCard?.recipients && card.digitalCard?.recipients.length > 0
      ? card.digitalCard.recipients[0].token
      : undefined

  const [shareToken, setShareToken] = useState<string | undefined>(
    digitalCardToken,
  )

  const [shouldShowJoin, setShouldShowJoin] = useState(false)

  const handlePreparationComplete = (digiCardOrder: DigitalCardOrder) => {
    setDigitalOrder(digiCardOrder)
    if (digiCardOrder.data.total === 0) {
      setIsLoading(true)
      actOrderPay()
    } else {
      actions.openActCheckout(cardId)
    }
  }

  const [showPaymentMethodForm, setShowPaymentMethodForm] = useState(false)

  const actOrderPay = async () => {
    const digitalCardPk = card?.digitalCard?.pk
    if (digitalCardPk) {
      const digiCard = await digitalCardOrderPayMutation.mutateAsync({
        digitalCardPk: digitalCardPk,
      })
      setIsLoading(false)
      if (digiCard.recipients.length > 0 && digiCard.recipients[0].token) {
        setShareToken(digiCard.recipients[0].token)
        actions.openActSuccess(cardId)
      }
    }
  }

  const recipientName =
    card?.digitalCard &&
    card?.digitalCard.recipients &&
    card?.digitalCard.recipients.length > 0 &&
    card?.digitalCard.recipients[0].contactRequest
      ? card.digitalCard.recipients[0].contactRequest.firstName
      : 'their'

  const fullBleeds = card ? factoryFullBleeds(card) : []

  const sendableCardId = card
    ? (selectedVariationId(card, fullBleeds) ?? card.detailedSendableCard?.id)
    : undefined

  const designColorsQuery = card?.id
    ? getDesignColorsBySendableCardID({ id: sendableCardId!, quantity: 5 })
    : undefined
  const [designColorsResponse] = useQueryFutures(designColorsQuery)
  const designColors: DesignColors = designColorsResponse
    ? {
        palette:
          !designColorsResponse.isUnresolved &&
          !designColorsResponse.error &&
          designColorsResponse.value
            ? (designColorsResponse.value as ColorFragment[])
            : undefined,
        isLoading: designColorsResponse.isUnresolved,
      }
    : {
        palette: undefined,
        isLoading: false,
      }

  const cardColor = designColors?.palette?.[0]

  const step = (path: string, card: CardFragment): React.ReactNode => {
    if (path === ActConfigRoute.path)
      return (
        <PrepareAct
          card={card}
          onBack={() => actions.openCard(card.id)}
          onPreparationComplete={handlePreparationComplete}
          hasCreatedDigitalOrder={!!digitalOrder}
        />
      )
    else if (path === ActCheckoutRoute.path)
      return (
        <>
          {digitalOrder && (
            <Flex
              style={{
                padding: '20px',
                flexDirection: 'column',
                background: '#f8f5f699',
                height: '100dvh',
              }}
            >
              <DialogHeader
                onBack={() => {
                  actions.openActConfiguration(card.id)
                }}
              />
              <ActCheckout
                order={digitalOrder}
                paymentFormDisplay={{
                  shouldShowPaymentForm: showPaymentMethodForm,
                  setShouldShowPaymentMethodForm: setShowPaymentMethodForm,
                }}
                item={{
                  title: card?.detailedSendableCard?.title ?? 'Custom Card',
                  imageUrl: card?.frontPreviewUrl,
                  isHorizontal: card?.isHorizontal ?? false,
                  price: digitalOrder?.data.items[0].amount ?? 0,
                  discount:
                    digitalOrder?.data.items[0].discounts[0] ?? undefined,
                }}
                onChangeAddon={() => {
                  console.log('on change addon')
                }}
                onChangeCard={() => console.log('change card')}
                onSubmit={actOrderPay}
                isProcessingPayment={digitalCardOrderPayMutation.isLoading}
              />
            </Flex>
          )}
        </>
      )
    else if (path === ActSuccessRoute.path)
      return (
        <div>
          <EditorCardColorBackground
            startColor="#F1E9F9"
            endColor={
              cardColor &&
              `rgb(${cardColor.red}, ${cardColor.green}, ${cardColor.blue})`
            }
          />
          <ShareScreen
            recipientName={recipientName}
            card={card}
            baseURL={baseURL}
            isSparse={isSparse}
            token={shareToken}
            onCopyToClipboard={() => {
              try {
                navigator.clipboard.writeText(`${baseURL}/act/${shareToken}`)
                return true
              } catch (err) {
                console.warn('Clipboard copy failed:', err)
                return false
              }
            }}
            onFinish={() => {
              if (isSparse) {
                setShouldShowJoin(true)
              } else {
                actions.openAccount()
              }
            }}
          />
        </div>
      )
    else return <></>
  }

  if (!route.subroute) {
    return null
  }

  return (
    <>
      {isLoading ? (
        <Dialog
          mobileBreakPointOverride={799}
          maxWidth={isMobile ? undefined : '360px'}
          mobileOpenHeight="0px"
          isOpen={true}
          onClose={() => {
            // what to do here
          }}
          outerWrapperStyle={{ display: 'flex', flexDirection: 'column' }}
          wrapperStyle={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: '1',
            overflowX: 'auto',
          }}
          shouldHideTitleBar={true}
          padding="20px"
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '20px',
            }}
          >
            <Image
              isActive
              image={{ url: card.frontPreviewUrl }}
              width={card.isHorizontal ? '210px' : '150px'}
              height={card.isHorizontal ? '150px' : '210px'}
            />
            <Text
              content="Generating Shareable Card"
              type="largeBody"
              color="primaryHeading"
              weight="semiBold"
            />
            <LoadingSpinner size="medium" />
          </div>
        </Dialog>
      ) : shouldShowJoin ? (
        <div
          style={{
            position: 'fixed',
            top: 0,
            width: '100%',
            height: '100%',
            overflowY: 'auto',
          }}
        >
          <ExclusiveOfferScreen
            onFinishedJoin={() => actions.openAccount()}
            onClose={() => actions.openAccount()}
          />
        </div>
      ) : (
        step(route.subroute.path, card)
      )}
    </>
  )
}

export default ActSetup
