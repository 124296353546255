import {
  CardSendType,
  FullAccessFeatureType,
} from 'src/graphql/generated/graphql'
import { PartialLine } from 'src/helpers/multitouch'
import {
  getDiscountedPrice,
  getPrice,
  getTotalCardCost,
} from 'src/orders/helpers'
import useSubscriptions from './useSubscriptions'
import { useFeatureAccess } from './useFeatureAccess'

const useOrderLinePricing = (
  orderSendType?: CardSendType,
  orderLine?: PartialLine,
) => {
  const sub = useSubscriptions()
  const { hasFeatureAccess: hasFreePremium } = useFeatureAccess([
    FullAccessFeatureType.FreePremiumCards,
  ])

  const cardCost = {
    baseCost: getPrice(orderLine?.card?.cost.entries, 'Card Cost'),
    finalCost: getPrice(orderLine?.cost?.entries, 'Card Cost'),
    upgradesCost: getPrice(orderLine?.card?.cost.entries, 'Card Upgrades'),
    finalUpgradesCost: getPrice(orderLine?.cost?.entries, 'Card Upgrades'),
  }

  const cardPrice = getTotalCardCost(cardCost.baseCost, cardCost.upgradesCost)

  const cardDiscountedPrice =
    cardCost.baseCost && cardCost.finalCost
      ? getDiscountedPrice(
          cardCost.baseCost,
          cardCost.finalCost,
          cardCost.upgradesCost,
          cardCost.finalUpgradesCost,
        )
      : undefined

  const cardDiscountMessage =
    cardDiscountedPrice !== undefined
      ? sub.hasAvailableHeartfeltSends
        ? 'This card is $0.00 because you have Heartfelt Promptings Cards Sends Available'
        : sub.hasAvailableCardSends
          ? 'This card is $0.00 because you have Cards Sends Available'
          : cardCost.finalCost?.currency === 'FREE'
            ? 'This card is $0.00 because it is your first card'
            : 'This card is discounted because you have a subscription or package'
      : undefined

  const cardUpgradesDiscountMessage =
    cardDiscountedPrice !== undefined &&
    cardCost.upgradesCost?.amount !== cardCost.finalUpgradesCost?.amount
      ? hasFreePremium
        ? 'This card upgrade is discounted because you have free premium designs'
        : cardCost.finalCost?.currency === 'FREE'
          ? 'This card upgrade is $0.00 because it is your first card'
          : 'This card upgrade is discounted because you have a subscription or package'
      : undefined

  const giftPrices = {
    baseCost:
      getPrice(orderLine?.baseCost?.entries, 'Gift Cost')?.asString ??
      getPrice(orderLine?.baseCost?.entries, 'Gift Card Cost')?.asString,
    cost:
      getPrice(orderLine?.cost?.entries, 'Gift Cost')?.asString ??
      getPrice(orderLine?.cost?.entries, 'Gift Card Cost')?.asString,
  }
  const hasGiftDiscount = giftPrices.baseCost !== giftPrices.cost

  const giftPrice = hasGiftDiscount
    ? (giftPrices.baseCost ?? '')
    : (giftPrices.cost ?? '')

  const giftDiscountPrice = hasGiftDiscount ? giftPrices.cost : undefined

  const giftDiscountMessage = hasGiftDiscount
    ? `Discounts from your ${sub.giftDiscountPlanTitle} subscription have been applied.`
    : undefined

  const getCardDiscountMessage = () => {
    const messageArray = [
      cardDiscountMessage,
      cardUpgradesDiscountMessage,
    ].filter(value => !!value)

    return messageArray.length > 0 ? messageArray.join('\n') : undefined
  }

  return {
    cardPrice,
    cardDiscountedPrice,
    cardDiscountMessage: getCardDiscountMessage(),
    giftPrice,
    giftDiscountPrice,
    giftDiscountMessage,
  }
}

export default useOrderLinePricing
